import React, { useState } from 'react'
import { Faqs, Text } from 'mf-ui-components'
import styled from 'styled-components'
import { Col, Row, Container } from 'styled-bootstrap-grid'
import parser from '../../../utils/htmlParser'
import { parseStringToJson } from '../../../utils/parseData'
import { marginBreakpoints, hideBlockAt } from '../../../utils/commonAttrs'
import lang, * as l10n from '../../../utils/l10n'
import fetch from 'isomorphic-fetch' // adding for Apollo to work: DO NOT REMOVE!
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const FAQComponent = styled.div`
  ${props => marginBreakpoints(props, 0)};
  ${props => hideBlockAt(props)}
`

const FAQBlock = styled(Row)``

const SearchBar = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.font.family.title};
  text-transform: none;
  letter-spacing: 0;
  padding: 0 13px;
  margin-bottom: 26px;
`

const CategoryList = styled(Col)`
  @media screen and (max-width: 576px) {
    margin-bottom: 26px;
  }
`

const CategoryItem = styled.div`
  background-color: ${props =>
    props.selected ? props.theme.tokens.accent.token.primary.regular : 'none'};
  border-radius: 4px;
  padding: 10px 15px;
  color: ${({ selected, theme }) =>
    selected
      ? theme.tokens.accent.onToken.primary.regular
      : theme.tokens.accent.base.secondary.regular};
  font-weight: 600;
  margin-top: 2px;
  cursor: pointer;
  text-decoration: ${({ selected }) => (selected ? 'none' : 'underline')};
`

const FAQList = styled(Col)``

export default function FAQs({
  categories,
  showCategories,
  showSearch,
  structuredDataSettings,
  childBlock,
  id,
  hiddenAt,
  marginBottom,
}) {
  const categoryList = parseStringToJson(categories)
  const parsedStructuredDataSettings = structuredDataSettings
    ? parseStringToJson(structuredDataSettings)
    : {}

  const [searchText, setSearchText] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(0)

  const {
    wpgraphql: {
      categories: { edges: categoryEdges },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          categories(first: 50) {
            edges {
              cursor
              node {
                name
                databaseId
                faqs(first: 500) {
                  edges {
                    cursor
                    node {
                      title(format: RENDERED)
                      content(format: RENDERED)
                      menuOrder
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const filteredCategories = categoryEdges
    .filter(x => categoryList.includes(`${x.node.databaseId}`))
    .filter(category => (category.node.faqs.edges.length > 0 ? true : false))
    .map(category => ({
      ...category,
      index: categoryList.findIndex(el => category.node.databaseId == el),
    }))
    .sort((a, b) => a.index - b.index)

  const enabledCategoriesForStructuredData = Object.keys(
    parsedStructuredDataSettings
  )
    .filter(categoryId => parsedStructuredDataSettings[categoryId])
    .map(Number)

  const filteredCategoriesWithStructuredDataEnabled = filteredCategories.filter(
    category =>
      enabledCategoriesForStructuredData.includes(category.node.databaseId)
  )

  const numberOfMatches = filteredCategories.reduce((acc, curr) => {
    return [
      ...acc,
      curr.node.faqs.edges.filter(faq => {
        return (
          faq.node.title.toLowerCase().includes(searchText.toLowerCase()) ||
          faq.node.content.toLowerCase().includes(searchText.toLowerCase())
        )
      }).length,
    ]
  }, [])

  const allQuestions = filteredCategories.reduce(
    (acc, { node }) => [...acc, ...node.faqs.edges],
    []
  )

  const questionsForStructuredData = filteredCategoriesWithStructuredDataEnabled.reduce(
    (acc, { node }) => [...acc, ...node.faqs.edges],
    []
  )

  const questionsToShow = showCategories
    ? filteredCategories[selectedCategory].node.faqs.edges
    : allQuestions

  const filteredQuestions = questionsToShow
    .filter(faq => {
      if (!searchText || searchText.length < 3) return true
      return (
        faq.node.title.toLowerCase().includes(searchText.toLowerCase()) ||
        faq.node.content.toLowerCase().includes(searchText.toLowerCase())
      )
    })
    .map(faq => {
      return {
        text: parser(faq.node.title),
        answer: parser(faq.node.content),
        order: faq.node.menuOrder,
      }
    })
    .sort((a, b) => {
      return a.order - b.order
    })

  const listSizes = showCategories
    ? {
        lg: 8,
        md: 8,
        sm: 8,
        xs: 12,
      }
    : { xs: 12 }

  const FAQ = (
    <>
      {questionsForStructuredData.length > 0 && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: questionsForStructuredData.map(q => ({
                '@type': 'Question',
                name: q.node.title,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: q.node.content,
                },
              })),
            })}
          </script>
        </Helmet>
      )}
      <FAQComponent id={id} hiddenAt={hiddenAt} marginBottom={marginBottom}>
        {showSearch && (
          <SearchBar
            onChange={e => setSearchText(e.target.value)}
            placeholder={l10n.faq_filter_input_placeholder[lang]}
          />
        )}
        <FAQBlock>
          {showCategories && (
            <CategoryList lg={4} md={4} sm={4} xs={12}>
              {filteredCategories.map((category, index) => (
                <CategoryItem
                  key={index}
                  selected={index === selectedCategory}
                  onClick={() => setSelectedCategory(index)}
                >
                  {category.node.name}{' '}
                  {searchText.length >= 3
                    ? `(${numberOfMatches[index]})`
                    : null}
                </CategoryItem>
              ))}
            </CategoryList>
          )}
          <FAQList {...listSizes}>
            <React.Fragment>
              {showCategories && (
                <Text styleName="title5" el="h4">
                  {filteredCategories[selectedCategory].node.name}
                </Text>
              )}
              {filteredQuestions.length > 0 ? (
                <Faqs faqsList={filteredQuestions} readMore="Read more" />
              ) : (
                <div style={{ marginTop: '20px' }}>
                  {l10n.faq_filter_no_matches_found[lang]}
                </div>
              )}
            </React.Fragment>
          </FAQList>
        </FAQBlock>
      </FAQComponent>
    </>
  )

  if (childBlock) return FAQ

  return <Container>{FAQ}</Container>
}

export const query = graphql`
  fragment faqFields on WPGraphQL_MfblocksFaqBlock {
    name
    attributes {
      categories
      showSearch
      showCategories
      structuredDataSettings
      id
      hiddenAt
      marginBottom
    }
  }
`
